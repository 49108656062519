<template>
  <div class="login-page">
    <div class="login-content">
      <!-- 扑克牌 -->
      <div class="logo-icon">
        <div class="outer2"></div>
        <div class="box1 word1">{{ titleData[props.from] || props.from }}</div>
      </div>
      <div class="main-content">
        <div class="left-image">
          <img
            v-if="props.from === 'ULTRA_BI'"
            src="../../img/background_main.jpg"
            class="image"
          />
          <img v-else src="../../img/background_main.png" class="image" />
        </div>
        <div class="ding-login-content" v-if="loginType === 'ding'">
          <div id="dingding-login-container"></div>
          <div class="flex-colunm">
            <div class="text text-gray">{{ "打开最新版钉钉App" }}</div>
            <div class="text text-tips">{{ "「消息」——「扫一扫」" }}</div>
            <a-button type="link" @click="goToPwdLogin">{{
              "账号登录"
            }}</a-button>
          </div>
        </div>
        <div class="ding-login-content" v-else-if="loginType === 'dingList'">
          <div class="login-title">{{ "请选择商户" }}</div>
          <div
            @click="activeUser = item"
            type="primary"
            class="user-block"
            :class="{ active: activeUser.merchantCode === item.merchantCode }"
            v-for="item in userList"
            :key="item.merchantCode"
          >
            <div>{{ item.merchantName }}</div>
          </div>
          <a-button
            class="login-btn ding-select-btn"
            type="primary"
            shape="round"
            @click="loginDing"
          >
            {{ "登录" }}
          </a-button>
        </div>
        <div class="login-input-content" v-else>
          <div class="login-title">
            <img src="../../img/background_poker.png" alt="" class="image" />
            账号登录
          </div>
          <a-row class="mtp20" v-if="!hasState">
            <a-input
              id="merchant-name"
              placeholder="商家名称"
              v-model:value="userInfo.merchantName"
              class="border-show"
              required
            />
          </a-row>
          <a-row class="mtp20">
            <a-input
              v-model:value="userInfo.userName"
              placeholder="用户名"
              class="border-show"
              required
            />
          </a-row>
          <a-row class="mtp20">
            <a-input-password
              id="pwd-input"
              placeholder="密码"
              class="border-show"
              :class="{ valid: userInfo.password }"
              required
              v-model:value="userInfo.password"
              @keyup.enter="submitForm"
              type="password"
            />
          </a-row>
          <a-row
            style="
              text-align: center;
              margin: 15px 0;
              color: red;
              font-size: 14px;
            "
            id="error-msg"
          >
            <span v-text="errorMsg"></span>
          </a-row>
          <a-button
            class="login-btn"
            type="primary"
            shape="round"
            @click="submitForm"
            :loading="loginLoading"
          >
            登录
          </a-button>
          <div class="text-group">
            <a-button
              style="padding-left: 0"
              type="link"
              @click="registerMerchant"
            >
              商户注册
            </a-button>
            <a-button
              style="padding-left: 0"
              type="link"
              @click="forgotPassword"
            >
              忘记密码
            </a-button>
            <a-button
              class="ding-login-btn"
              type="link"
              @click="getDingtalkCode"
            >
              钉钉登录
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    :title="'商户注册'"
    v-model:visible="registVisible"
    v-bind="modalBind"
  >
    <div class="flex-c-c flex-col" v-if="showPercent">
      <p style="font-weight: bold">{{ "商户注册中……" }}</p>
      <a-progress type="circle" :percent="percent" />
    </div>
    <a-form v-else :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item required :label="'国家'" v-if="!isCN">
        <SelectCountry v-model:code="modalData.country" />
      </a-form-item>
      <a-form-item required :label="'商户名称'">
        <a-input v-model:value="modalData.merchantName" />
      </a-form-item>
      <a-form-item required :label="'用户名'">
        <a-input v-model:value="modalData.userName" />
      </a-form-item>
      <a-form-item required :label="'手机号'">
        <a-input v-model:value="modalData.mobile" />
      </a-form-item>
      <a-form-item required :label="'邮箱'">
        <a-input v-model:value="modalData.email" />
      </a-form-item>
      <a-form-item required :label="'商户类型'">
        <a-select v-model:value="modalData.merchantType">
          <a-select-option value="COMPANY"> 单个公司 </a-select-option>
          <a-select-option value="GROUP"> 集团公司 </a-select-option>
        </a-select>
      </a-form-item>
      <template v-if="props.from === 'FAST_BOOKS'">
        <a-form-item required :label="'公司名称'">
          <a-input v-model:value="modalData.companyName" />
        </a-form-item>
        <a-form-item required :label="'银行账号'">
          <a-input v-model:value="modalData.bankAccount" />
        </a-form-item>
        <a-form-item required :label="'开户行'">
          <a-input v-model:value="modalData.bankName" />
        </a-form-item>
      </template>
    </a-form>
    <template #footer>
      <a-button key="back" @click="registVisible = false">{{
        `取消`
      }}</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="confirmLoading"
        @click="registHandle"
      >
        {{ `注册` }}
      </a-button>
    </template>
  </a-modal>
  <ResetPassword ref="resetPasswordRef" />
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, onMounted } from "vue"
import sha512 from "js-sha512"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message, Modal } from "ant-design-vue"
import ResetPassword from "@/components/ResetPassword"
import SelectCountry from "@/components/SelectCountry"
import { debounce, cloneDeep } from "lodash"
import { useRoute } from "vue-router"
const route = useRoute()
const emits = defineEmits(["login"])
const loginType = ref("pwd") //pwd账号密码 ding钉钉扫码 dingList扫码回来选择商户列表
const titleData = ref({
	ULTRA_BI: "BI",
	FAST_BOOKS: "FASTBOOKS",
	RENTAL_APP: "租赁系统",
	AUDIT_REPORT_APP: "审计报告",
})
const resetPasswordRef = ref()
const loginLoading = ref(false)
const props = defineProps({
	from: String,
})
const hasState = computed(() => {
	return route.query.state && route.query.state !== "null"
})
onMounted(() => {
	if (hasState.value) {
		userInfo.value.merchantName = route.query.state
	}
})
const submitForm = debounce(async () => {
	if (loginLoading.value) return
	const data = {}
	for (const p in userInfo.value) {
		data[p] = userInfo.value[p]
	}
	loginLoading.value = true
	data.password = sha512(data.password)
	data.authSysCode = props.from === "ULTRA_BI" ? "BI" : props.from
	axios
		.post(api.login, data)
		.then(async (res) => {
			if (res) {
				emits("login", res)
			}
			loginLoading.value = false
		})
		.catch(() => {
			loginLoading.value = false
		})
})
const registVisible = ref(false)
const showPercent = ref(false)
const confirmLoading = ref(false)
const percent = ref(0)
const modalData = ref({
	country: "",
	merchantName: "",
	userName: "",
	mobile: "",
	email: "",
})
const userInfo = ref({})
const activeUser = ref({})
const modalBind = computed(() => {
	const data = {
		maskClosable: false,
	}
	if (showPercent.value) {
		data.footer = null
		data.closable = false
		data.keyboard = false
	}
	return data
})
const authCode = ref()
const userList = ref([])
const loginDing = () => {
	userInfo.value.merchantName = activeUser.value.merchantName
	userInfo.value.authCode = authCode.value
	userInfo.value.userName = activeUser.value.userName
	submitForm(false)
}
const callbackDing = (query) => {
	axios.post(`${api.callbackDing}?authCode=${query.authCode}&state=${query.state}`).then((res) => {
		if (res) {
			authCode.value = res.authCode
			const userList = res.userList
			if (userList.length === 1) {
				userInfo.value.merchantName = userList[0].merchantName
				userInfo.value.authCode = res.authCode
				userInfo.value.userName = userList[0].userName
				submitForm(false)
			} else if (userList.length >= 1) {
				removeIframe()
				loginType.value = "dingList"
				activeUser.value = userList[0]
				userList.value = userList
			}
		}
	})
}

const removeIframe = () => {
	document.querySelector("iframe").remove()
}
const getDingtalkCode = () => {
	loginType.value = "ding"
	axios.post(api.dingtalk, {}).then((res) => {
		const { appId, responseType, scope, state } = res
		window.DTFrameLogin(
			{
				id: "dingding-login-container",
				width: 300,
				height: 300,
			},
			{
				redirect_uri: encodeURIComponent(window.location.href),
				client_id: appId,
				scope,
				response_type: responseType,
				state,
				prompt: "consent",
			},
			(loginResult) => {
				const { authCode, state } = loginResult
				// 这里可以直接进行重定向
				// 也可以在不跳转页面的情况下，使用code进行授权
				callbackDing({
					authCode,
					state,
				})
			},
			(errorMsg) => {
				// 这里一般需要展示登录失败的具体原因
				alert(`Login Error: ${errorMsg}`)
			}
		)
	})
}
const goToPwdLogin = () => {
	removeIframe()
	loginType.value = "pwd"
}
const registerMerchant = () => {
	showPercent.value = false
	registVisible.value = true
}
const registHandle = async () => {
	if (!modalData.value.country) {
		return message.warning("请选择国家")
	}
	if (!modalData.value.merchantName) {
		return message.warning("请输入商户名称")
	}
	if (!modalData.value.userName) {
		return message.warning("请输入用户名")
	}
	if (!modalData.value.mobile) {
		return message.warning("请输入手机号")
	}
	if (!modalData.value.email) {
		return message.warning("请输入邮箱")
	}
	if (!modalData.value.merchantType) {
		return message.warning("请选择商户类型")
	}
	const data = cloneDeep(modalData.value)
	if (props.from === "FAST_BOOKS") {
		data.defaultRoleCode = "fastbooks_admin"
	}
	Object.assign(data, {
		appCode: props.from,
		defaultShipperName: "默认货主",
		defaultShopName: `${data.merchantName}B2B店铺`,
		defaultShopPlatform: "SHOPSPADE_B2B",
		defaultWarehouseName: "默认仓库",
		defaultWmsAppCode: "VIRTUAL_WMS",
	})
	confirmLoading.value = true
	try {
		await axios.post(api.registerMerchant, data)
		confirmLoading.value = false
		showPercent.value = true
		getRegistPercent()
	} catch (err) {
		confirmLoading.value = false
	}
}
const timer = ref()
const getRegistPercent = async () => {
	if (percent.value === 100) {
		clearTimeout(timer.value)
		timer.value = null
		registVisible.value = false
		Modal.success({
			title: "注册成功",
			content: "初始密码为手机号",
			okText: "去登录",
			onOk: () => {
				userInfo.value.merchantName = modalData.value.merchantName
				userInfo.value.userName = modalData.value.userName
				userInfo.value.password = modalData.value.mobile
				submitForm()
			},
		})
		return
	}
	const res = await axios.post(api.queryRegisterProgress, {
		merchantName: modalData.value.merchantName,
	})
	percent.value = res.progress
	timer.value = setTimeout(async () => {
		getRegistPercent()
	}, 1000)
}
const forgotPassword = () => {
	if (resetPasswordRef.value) {
		resetPasswordRef.value.open()
	}
}
</script>

<style scoped lang="scss">
$primary-color: #1890ff; // 全局主色
$white: #fff;
.login-page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 30px 0;

  &::before {
    content: url("../../img/background_rt.png");
    position: absolute;
    top: 0;
    right: 0;
  }

  &::after {
    content: url("../../img/background_bottom.png");
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .login-content {
    box-shadow: 10px 15px 17px -5px rgba(0, 0, 0, 0.18);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px;
    padding: 0 80px 120px;
    position: relative;
    z-index: 10;

    .logo-icon {
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      margin-top: -30px;

      .outer2 {
        width: 74px;
        height: 89px;
        background: url("../../img/background_poker.png") no-repeat;
        margin-right: 20px;
      }

      .word1 {
        overflow-wrap: break-word;
        color: $primary-color;
        font-size: 36px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        margin-top: 10px;
      }
    }

    .main-content {
      display: flex;
      width: 100%;

      .left-image {
        width: 60%;
        min-width: 262px;
        margin-right: 50px;
        .image {
          display: block;
          width: 100%;
          object-fit: contain;
        }
      }

      .login-btn {
        display: inline-block;
        width: 300px;
        height: 50px;
        font-size: 24px;
      }

      .text-group {
        margin: 25px 0 0 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ding-login-btn {
          margin-left: 20px;
          padding-right: 0;
        }
      }

      .ding-login-content {
        width: 300px;
        font-size: 16px;

        #dingding-login-container {
          min-height: 300px;
        }

        .flex-colunm {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;

          .text-tips {
            margin: 16px 0 30px;
          }
        }
      }
    }
    .mtp20 {
      position: relative;
      margin-top: 20%;
      &:deep(.ant-input) {
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC-Regular;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-radius: 0;
        box-shadow: none;
      }
      .label {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px 0 4px 10px;
        font-size: 1.5em;
        pointer-events: none;
        color: #444;
        transition: 0.3s;
      }
    }
  }

  .login-title {
    width: 118px;
    height: 40px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
    font-family: PingFang SC-ExtraLight, PingFang SC;
    text-align: right;
    white-space: nowrap;
    line-height: 40px;
    display: flex;
    font-weight: bold;
    align-items: center;
    .image {
      object-fit: cover;
      width: 28px;
      display: none;
      margin-right: 4px;
    }
  }
}

input:focus {
  outline: 0px solid $white !important;
}

.user-block {
  width: 100%;
  margin-top: 20px;
  height: 50px;
  box-shadow: 0px 0px 10px 0px #e6f4ff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  padding-left: 16px;
  cursor: pointer;

  &.active {
    background: rgba(0, 136, 255, 0.1);
    border-color: $primary-color;
  }
}

.ding-select-btn {
  margin-top: 30px;
}

@media only screen and (max-width: 576px) {
  .login-page {
    &::before {
      content: "";
    }
    &::after {
      content: "";
    }
    .login-content {
      box-shadow: none;
      padding: 0;
      width: 100%;
      padding: 0 20px;
      .logo-icon {
        visibility: hidden;
      }
      .main-content {
        .login-title .image {
          display: block;
        }
        .left-image {
          position: fixed;
          top: 32px;
          width: 262px;
          left: 50%;
          transform: translateX(-50%);
        }
        .mtp20 {
          margin-top: 10%;
        }
        .login-input-content {
          width: 100%;
          transform: translateY(-10%);
        }
        .login-btn {
          width: 100%;
          height: 48px;
          font-size: 20px;
        }
        .text-group {
          display: none;
        }
      }
    }
  }
}

.lang-select {
  position: fixed;
  top: 10px;
  right: 10px;
}

.lang-select :deep(.icon-global) {
  color: $white;
}

.login-page .login-content .mtp20 .valid + span.label,
.login-page .login-content .mtp20 input:focus + span.label,
.login-page .login-content .mtp20 input:valid + span.label {
  transform: translateY(-35px) translateX(-10px);
  font-size: 1.25em;
  background-color: $primary-color;
  color: $white;
  padding: 2px 4px;
  border-radius: 2px;
}
</style>
